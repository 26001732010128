* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-poppins text-gray-800; /* Default font and text color */
}

/* Custom styles */
.header-top-strip {
  @apply bg-black text-white py-2;
}

.nav-link {
  @apply text-gray-700 hover:text-black transition-colors;
}

.nav-link.active {
  @apply text-black font-semibold;
}

.search-overlay {
  @apply fixed inset-0 bg-black bg-opacity-50 z-50 flex items-start justify-center pt-20;
}

.sidebar {
  @apply fixed inset-y-0 left-0 w-64 bg-white shadow-lg z-50 transform transition-transform duration-300 ease-in-out;
}

.sidebar-open {
  @apply translate-x-0;
}

.sidebar-closed {
  @apply -translate-x-full;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.animate-fade-in {
  animation: fade-in 1.5s ease-in-out;
}

.animate-slide-in {
  animation: slide-in 1s ease-in-out;
}

.animate-bounce {
  animation: bounce 2s infinite;
}


/* Custom Scrollbar Styling */
.custom-scrollbar {
  scrollbar-width:thin;
  scrollbar-color: #4f46e5 #f3f4f6;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f3f4f6;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #4f46e5;
  border-radius: 4px;
  border: 2px solid #f3f4f6;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #3b82f6;
}




:root {
  --color-131921: #131921;
  --color-3b4149: #3b4149;
  --color-febd69: #febd69;
  --color-232f3e: #232f3e;
  --color-bf4800: #bf4800;
  --color-f5f5f7: #f5f5f7;
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --SecondaryColor: hsl(187, 67%, 36%);
  --color-ededed: #ededed;
  --color-777777: #777777;
  --color-1c1c1b: #1c1c1b;
}




.section-heading {
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 30px;
}
.card-wrapper {
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}
body {
  margin: 0;
  font-family: "Poppins","Roboto";
}
a {
  text-decoration: none;
  display: inline-block;
}
.form-control:focus {
  box-shadow: none;
  border-color: inherit;
}

.gap-10 {
  gap: 10px;
}
.gap-15 {
  gap: 15px;
}
.gap-30 {
  gap: 30px;
}

.button {
   background: var(--gradientColor);
  color: white;
  font-size: 14px;
  padding: 13px 33px;
  border-radius: 25px;
}
.button:hover {
  background: var(--SecondaryColor);
  color: white;
}



/* Footer Part Ends Here */
/* Wrapper for the breadcrumb with background image */
.breadcrumb-wrapper {
  background: url('../public/images/breadcrumb-bg.webp') center center / cover no-repeat;
  padding: 40px 30px;
  /* border-radius: 12px; */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  color: #ffffff; /* Text color for contrast */
  position: relative;
  overflow: hidden;
}

/* Overlay for the background */
.breadcrumb-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for text readability */
  z-index: 1;
  border-radius: inherit;
}

/* Breadcrumb content */
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Poppins", sans-serif;
  z-index: 2; /* Bring breadcrumb text above overlay */
  position: relative;
}

/* Breadcrumb links */
.breadcrumb-link {
  color: #f1f1f1;
  font-weight: 500;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: color 0.3s ease, transform 0.3s ease;
}

.breadcrumb-link:hover {
  color: #ffcc00; /* Highlight color */
  text-decoration: underline;
  transform: translateY(-2px);
}

/* Active breadcrumb item */
.breadcrumb-item.active {
  color: #dddddd;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
}

/* Breadcrumb separator */
.breadcrumb-separator {
  color: #bbbbbb;
  font-size: 18px;
  margin: 0 5px;
  display: flex;
  align-items: center;
}

/* Breadcrumb icon */
.breadcrumb-icon {
  margin-right: 5px;
  font-size: 18px;
  color: #f1f1f1;
  transition: transform 0.3s ease;
}

.breadcrumb-link:hover .breadcrumb-icon {
  transform: scale(1.2);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .breadcrumb-wrapper {
    padding: 20px;
    background: url('../public/images/breadcrumb-bg.webp') center center / cover no-repeat;
  }

  .breadcrumb-link,
  .breadcrumb-item.active {
    font-size: 14px;
  }

  .breadcrumb-separator {
    font-size: 14px;
  }
}

@media (min-width: 1025px) {
  .breadcrumb-wrapper {
    padding: 40px 30px;
  }

  .breadcrumb-link,
  .breadcrumb-item.active {
    font-size: 16px;
  }

  .breadcrumb-separator {
    font-size: 18px;
  }
}
/* Home Part */
.main-banner {
  height: 600px; /* Larger height for main banner */
  position: relative;
}

.main-banner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 0.75rem; /* Match the 'rounded-3' class */
}

.main-banner-content {
  top: 15%;
  left: 5%;
}

.main-banner-content h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.main-banner-content h5 {
  color: var(--SecondaryColor);
  font-size: 42px;
  line-height: 54px;
  letter-spacing: -2px;
  font-weight: 500;
  text-transform: none;
}

.main-banner-content p {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.4px;
  color: aliceblue;
}

.small-banner {
  position: relative;
  width: 49%;
  height: 300px;
  box-sizing: border-box; /* Include padding and border in width and height */
  padding: 0; /* Remove padding */
}

.small-banner img {
  width: 100%;
  height: 300px; /* Set a uniform height smaller than main banner */
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 0.75rem; /* Match the 'rounded-3' class */
}

.category-title {
  position: absolute;
  bottom: 0; /* Align at the bottom */
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px 0;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  border-bottom-left-radius: 0.75rem; /* Match the border radius */
  border-bottom-right-radius: 0.75rem; /* Match the border radius */
  box-sizing: border-box; /* Ensure padding does not add to the width */
  overflow: hidden; /* Ensure content does not overflow */
  width: 100%; /* Ensure it spans the full width of the image */
  margin: 0; /* Remove any margin */
}

/* home wrapper 2 */
/* .home-wrapper-2 {
  background-color: var(--color-f5f5f7);
} */

.home-wrapper-2 {
  background-color: var(--color-f5f5f7);
}

.services {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.service-item {
  display: flex;
  align-items: center;
  gap: 15px;
  flex: 1;
}

.service-icon {
  width: 40px;
  height: 40px;
  color: #232f3e; /* Updated icon color */
}

.services h6 {
  font-size: 12px;
  margin: 0;
  font-weight: bold;
}

.services p {
  font-size: 13px;
  margin: 0;
  color: #555;
}

.service-item div {
  flex: 1;
}

/* home wrapper 2 ends */

/* categories section */

.categories{
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}
.categories > div{
  width: 25%;
  padding: 10px 10px 10px 20px;
}

.categories>div>div{
  width: 60%;
}

.categories img{
  margin-top: 10px;
  margin-bottom: 10px;
  width: 40%;
  height: 130px
}

.categories>div:nth-child(1),
.categories>div:nth-child(2),
.categories>div:nth-child(3),
.categories>div:nth-child(4){
  border-bottom: 1px solid var(--color-ededed); 
}

.categories>div:nth-child(1),
.categories>div:nth-child(2),
.categories>div:nth-child(3){
  border-right: 1px solid var(--color-ededed); 
}

.categories>div:nth-child(5),
.categories>div:nth-child(6),
.categories>div:nth-child(7){
  border-right: 1px solid var(--color-ededed); 
}

/* BLog Part */
/* Overall layout */
/* Store Wrapper */
.store-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px 5px;
  background: linear-gradient(to bottom, #ffffff, #f7f7f9);
  border-radius: 24px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.store-wrapper:hover {
  transform: translateY(-4px);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.15);
}

/* Filter Grid and Controls */
.filter-sort-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  padding: 25px 30px;
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease;
}

.filter-sort-grid:hover {
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
}

.grid-controls {
  display: flex;
  align-items: center;
  gap: 20px;
}

.product-count {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

/* Divider Lines */
.divider-line-vertical {
  height: 30px;
  width: 2px;
  background-color: #ddd;
  margin: 0 2px;
}

.divider-line-horizontal {
  width: 100%;
  height: 2px;
  background-color: #e5e5e5;
  margin: 30px 0;
}

/* Sort Controls */
.sort-controls {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-left: auto;
  flex-wrap: wrap;
}

.sort-controls label {
  font-size: 16px;
  font-weight: 600;
  color: #444;
  display: flex;
  align-items: center;
  gap: 10px;
}

.sort-controls select {
  padding: 12px 18px;
  border: 1px solid #ddd;
  border-radius: 12px;
  font-size: 15px;
  color: #333;
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.sort-controls select:hover,
.sort-controls select:focus {
  border-color: #007bff;
  box-shadow: 0 0 12px rgba(0, 123, 255, 0.3);
}

/* Icon Styling */
.icon {
  font-size: 30px;
  color: #888;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.icon:hover {
  color: #0056d2;
  transform: scale(1.1);
}

.icon.active {
  color: #0056d2;
  transform: scale(1.2);
}

/* Products List Grid */
.products-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  padding: 30px 0;
}

/* Product Card */
.product-card {
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.product-card img {
  width: 100%;
  height: auto;
  border-radius: 20px 20px 0 0;
  object-fit: cover;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.product-card:hover img {
  transform: scale(1.1);
  filter: brightness(1.1);
}

.product-info {
  padding: 20px;
  text-align: center;
  background: #f9f9f9;
  border-top: 1px solid #eee;
}

.product-info h3 {
  font-size: 18px;
  font-weight: 600;
  color: #222;
  margin-bottom: 10px;
  transition: color 0.3s ease;
}

.product-info h3:hover {
  color: #0056d2;
}

.product-info p {
  font-size: 14px;
  color: #555;
}

/* Action Bar */
.action-bar {
  display: flex;
  justify-content: space-around;
  padding: 15px 0;
  border-top: 1px solid #ddd;
  background: #ffffff;
}

.action-btn {
  font-size: 20px;
  color: #777;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.action-btn:hover {
  color: #0056d2;
  transform: scale(1.2);
}

/* Empty State */
.empty-state {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #aaa;
  margin-top: 50px;
  letter-spacing: 0.5px;
  opacity: 0.9;
}

/* Responsive Adjustments */

/* Mobile View */
@media (max-width: 768px) {
  .filter-sort-grid {
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
  }

  .sort-controls {
    width: 100%;
    margin-left: 0;
  }

  .sort-controls select {
    width: 100%;
  }

  .products-list {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
}

/* Tablet View */
@media (max-width: 1024px) {
  .filter-sort-grid {
    flex-direction: row;
    justify-content: space-between;
  }

  .products-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }
}

/* Desktop View */
@media (min-width: 1025px) {
  .products-list {
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
  }
}




/* Contact Page */
.contact-inner-wrapper {
  padding: 30px 20px;
  border-radius: 15px;
  background-color: white;
  gap: 15px;
}
.contact-inner-wrapper > div {
  width: 48%;
}
.contact-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
}
.contact-inner-wrapper form .form-control {
  background-color: var(--color-f5f5f7);
  border-radius: 10px;
  border-color: transparent;
  padding: 10px;
}
.contact-inner-wrapper ul {
  list-style: none;
}
.contact-inner-wrapper address,
.contact-inner-wrapper a,
.contact-inner-wrapper p {
  color: var(--color-777777);
}
/* Contact Page ends */

/* Wishlist Page */
.wishlist-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  padding: 10px;
}

.wishlist-grid.single-item {
  grid-template-columns: 1fr;
  max-width: 40%;
  margin: 0 auto;
}

/* Wishlist Card */
.wishlist-card {
  background: linear-gradient(145deg, #ffffff, #f7f8fa); /* Modern gradient */
  border: 1px solid #d6dce7;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.wishlist-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

/* Remove Button */
.remove-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #000000;
  color: #fff;
  border: none;
  border-radius: 50%;
  font-size: 1rem;
  width: 36px;
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.remove-btn:hover {
  background: #d84040;
}

/* Product Image */
.wishlist-card-image {
  background: #ffffff;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-bottom: 1px solid #d6dce7;
}

.wishlist-card-image img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.wishlist-card-image:hover img {
  transform: scale(1.1);
}

/* Product Details */
.wishlist-card-details {
  padding: 20px;
  text-align: center;
}

.wishlist-title {
  font-size: 1.2rem;
  font-weight: 700;
  color: #333b42;
  margin-bottom: 10px;
}

.wishlist-price {
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 20px;
}

/* Action Buttons */
.wishlist-card-actions {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.wishlist-view-btn,
.wishlist-add-btn {
  flex: 1;
  padding: 10px;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: none;
}

.wishlist-view-btn {
  background: linear-gradient(145deg, #080c24, #161929); /* Dark muted gradient */
  color: #fff;
}

.wishlist-view-btn:hover {
  background: linear-gradient(145deg, #161929, #080c24);
}

.wishlist-add-btn {
  background: linear-gradient(145deg, #0032a6, #020a1d); /* Soft vibrant blue */
  color: #fff;
}

.wishlist-add-btn:hover {
  background: linear-gradient(145deg, #041132, #0032a6);
}

/* Empty Wishlist */
.empty-wishlist {
  grid-column: 1 / -1;
  text-align: center;
  font-size: 1.3rem;
  color: #8892b0;
  margin-top: 50px;
}

/* Additional Responsiveness */
@media (max-width: 768px) {
  .wishlist-card {
    border-radius: 10px;
  }

  .wishlist-card-details {
    padding: 15px;
  }

  .wishlist-view-btn,
  .wishlist-add-btn {
    padding: 8px;
    font-size: 0.85rem;
  }

  .wishlist-card-image {
    height: 180px;
  }
}

@media (max-width: 480px) {
  .wishlist-title {
    font-size: 1rem;
  }

  .wishlist-price {
    font-size: 0.9rem;
  }

  .wishlist-card {
    margin-bottom: 15px;
  }
}

.compare-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center; /* Center align single product */
}

.compare-grid.single-item {
  grid-template-columns: 35%; /* Make the single item take 35% of the width */
}

.compare-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.compare-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.remove-btn {
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: #ff4d4f;
  border: none;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.remove-btn:hover {
  background-color: #e63946;
}

.compare-card-image {
  padding: 25px;
  text-align: center;
  background-color: #f9f9f9;
  display: flex; /* Use flexbox for alignment */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 200px; /* Ensure a fixed height to align images consistently */
}

.compare-card-image img {
  max-height: 100%; /* Ensure the image does not exceed the container height */
  max-width: 100%; /* Ensure the image does not exceed the container width */
  object-fit: contain; /* Maintain aspect ratio */
}


.compare-card-details {
  padding: 20px;
  text-align: center;
}

.compare-title {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px;
  word-break: break-word;
}

.compare-price {
  color: #28a745;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.compare-specs {
  text-align: left;
  padding: 0 20px;
  font-size: 14px;
  color: #666666;
}

.spec-item {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.spec-item span {
  font-weight: bold;
  color: #333333;
}

.empty-compare {
  text-align: center;
  font-size: 18px;
  color: #888888;
  padding: 50px 0;
}

@media (max-width: 768px) {
  .compare-grid.single-item {
    grid-template-columns: 90%; /* Adjust to full width on smaller screens */
  }

  .compare-card {
    width: 100%;
  }

  .remove-btn {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }

  .compare-card-image img {
    max-height: 150px;
  }

  .compare-title {
    font-size: 18px;
  }

  .compare-price {
    font-size: 16px;
  }

  .compare-specs {
    font-size: 13px;
  }
}


/*  auth card */

.auth-card {
  padding: 20px;
  background-color: white;
  width: 500px;
  margin: 30px auto;
  border-radius: 10px;
}
.auth-card h3 {
  font-size: 19px;
  font-weight: 500;
  color: var(--color-777777);
}
.auth-card p {
  font-size: 13px;
  color: var(--color-777777);
}
.auth-card .form-control {
  height: 50px;
  background-color: var(--color-f5f5f7);
  color: var(--color-777777);
  font-size: 14px;
  border-radius: 10px;
  border-color: transparent;
}
.auth-card a {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-1c1c1b);
}
.signup {
  background-color: var(--color-febd69);
  color: var(--color-131921);
  border: none;
}
.signup:hover {
  background-color: var(--color-131921);
  color: white;
}
/*  auth card  ends*/

/* Policy Pages */

.policy {
  background-color: white;
  padding: 30px;
}
/* Policy Pages  ends */

/* Main Product Page Styling */

/* Main Product Image Section */
/* Main Product Image */
/* Main Product Image */

/* Thumbnails */
/* Thumbnails */
/* Global Enhancements */
/* body {
  font-family: 'Roboto', sans-serif;
  background-color: #f8f9fa;
  color: #333;
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  color: #333;
  font-weight: 700;
}

a {
  text-decoration: none;
  color: inherit;
} */

/* General Layout & Spacing */
/* .container {
  max-width: 1200px;
  margin: 0 auto;
} */

/* Main Product Wrapper */
.main-product-wrapper {
  background-color: #ffffff;
  padding: 50px 30px;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  margin-top: 50px;
  transition: all 0.3s ease;
}

.main-product-wrapper:hover {
  box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
}

/* Product Details Section */
.main-product-details {
  flex: 1 1 50%; /* Ensures details take 50% of the row */
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
  padding: 40px;
  border-radius: 18px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s ease;
}

.main-product-details:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 45px rgba(0, 0, 0, 0.15);
}

.main-product-details h1 {
  font-size: 34px;
  font-weight: 900;
  margin-bottom: 20px;
  color: #212529;
  text-transform: capitalize;
  letter-spacing: 1px;
  text-align: center;
}

.main-product-details .product-price {
  font-size: 36px;
  color: #e63946;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.main-product-details p {
  font-size: 15px;
  color: #495057;
  line-height: 1.8;
  margin-bottom: 15px;
}

.main-product-details strong {
  font-weight: 800;
  color: #343a40;
}

/* Product Tabs Styling */
.product-tabs {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 30px;
  border-bottom: 3px solid #e9ecef;
}

.product-tabs button {
  background: none;
  border: none;
  padding: 12px 20px;
  font-size: 18px;
  font-weight: 700;
  color: #6c757d;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.product-tabs button.active {
  color: #007bff;
  border-bottom: 4px solid #007bff;
}

.product-tabs button:hover {
  color: #0056b3;
  transform: scale(1.1);
}

/* Review Section */
.review-input {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 8px;
  resize: none;
  margin-top: 15px;
  background: #f8f9fa;
  transition: all 0.3s ease-in-out;
}

.review-input:focus {
  border-color: #007bff;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);
}

.btn-submit-review {
  background: #007bff;
  color: #fff;
  padding: 12px 20px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  margin-top: 20px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 123, 255, 0.2);
  transition: all 0.3s ease-in-out;
}

.btn-submit-review:hover {
  background: #0056b3;
  transform: translateY(-3px);
  box-shadow: 0 15px 30px rgba(0, 123, 255, 0.4);
}

/* Enhanced Product Image */
.main-product-image img {
  width: 100%;
  height: auto;
  border-radius: 18px;
  transition: all 0.3s ease;
}

.main-product-image img:hover {
  transform: scale(1.1);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  filter: brightness(1.1);
}

/* Call-to-Action Buttons */
.product-actions {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.product-actions button {
  padding: 14px 28px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.product-actions button:hover {
  transform: translateY(-2px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
}

.product-actions .btn-primary {
  background: linear-gradient(to right, #007bff, #0056b3);
  color: #fff;
}

.product-actions .btn-outline-danger {
  border: 2px solid #e63946;
  color: #e63946;
  background: transparent;
}

.product-actions .btn-outline-danger:hover {
  background: #e63946;
  color: #fff;
}

/* Related Products Section */
.related-products-wrapper {
  margin-top: 60px;
}

.related-products-wrapper .section-heading {
  font-size: 34px;
  margin-bottom: 40px;
  color: #333;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-align: center;
}

.related-products-wrapper .responsive-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
}

.related-products-wrapper .responsive-grid .product-card {
  border-radius: 12px;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.related-products-wrapper .responsive-grid .product-card:hover {
  transform: translateY(-6px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}

/* Share Icons */
.share-icons {
  margin-top: 20px;
  display: flex;
  gap: 15px;
  justify-content: center;
}

.share-icons a {
  color: #333;
  font-size: 24px;
  transition: all 0.3s ease;
}

.share-icons a:hover {
  color: #007bff;
  transform: scale(1.1);
}

/* Small Screen Adjustments */
@media (max-width: 768px) {
  .main-product-wrapper {
    padding: 20px;
  }

  .main-product-details {
    padding: 20px;
  }

  .product-title {
    font-size: 32px;
  }

  .product-price {
    font-size: 28px;
  }

  .product-actions button {
    font-size: 16px;
    padding: 14px 24px;
  }

  .product-tabs button {
    font-size: 14px;
    padding: 10px 20px;
  }

  .related-products-wrapper .responsive-grid {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }

  .product-actions .btn-outline-danger {
    font-size: 16px;
    padding: 12px 24px;
  }
}

@media (min-width: 1200px) {
  .main-product-wrapper {
    padding: 50px 40px;
  }

  .product-title {
    font-size: 42px;
  }

  .product-price {
    font-size: 36px;
  }

  .product-actions button {
    padding: 18px 32px;
  }
}


/* Cart Page */
.cart-header,
.cart-data {
  border-bottom: 1px solid #eaeaea;
}
.cart-header h4 {
  font-size: 13px;
  color: var(--color-777777);
}
.cart-col-1 {
  width: 40%;
}
.cart-col-1 p {
  color: var(--color-777777);
  font-size: 14px;
}
.cart-col-2 {
  width: 10%;
}
.cart-col-3 {
  width: 15%;
}
.cart-col-4 {
  width: 15%;
}
.cart-data h5.price {
  font-size: 14px;
  color: var(--color-1c1c1b);
}
/* Cart Page Ends here */

/* Shippping Page */

.total {
  font-size: 14px;
  color: var(--color-1c1c1b);
}
.total-price {
  font-size: 16px;
  color: var(--color-777777);
}
/* Shipping page ends here */


/* product card */



/* Compare Product */
.compare-product-card {
  width: 100%;
  height: 100%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-card-image img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin-bottom: 10px;
}

.compare-product-details {
  text-align: center;
}

.title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.price {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 15px;
}



.about-us-section {
  padding: 40px 0;
}

.about-us-section img {
  max-width: 100%;
  height: auto;
}

.about-us-section .content {
  padding: 20px;
}


.container-xxl{
  padding-bottom: 50px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  overflow: hidden;
  width: 100% !important;
}
